import {
  ArrowDownIcon,
  ArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon,
  ArrowLongUpIcon,
  ArrowRightCircleIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  ArrowTrendingUpIcon,
  ArrowUpIcon,
  ArrowUpOnSquareIcon,
  ArrowsPointingOutIcon,
  ArrowsRightLeftIcon,
  CalendarDaysIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CloudArrowUpIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  HomeIcon,
  InformationCircleIcon as InformationCircleOutline,
  MagnifyingGlassIcon,
  PencilIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  Squares2X2Icon,
  XCircleIcon as XCircleIconOutline,
  XMarkIcon,
  FunnelIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline'
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowsUpDownIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ClockIcon as ClockIconSolid,
  ExclamationCircleIcon as ExclamationCircleSolind,
  ExclamationTriangleIcon as ExclamationTriangleSolid,
  InformationCircleIcon as InformationCircleSolid,
  LinkIcon,
  PencilIcon as PencilSolid,
  PlayCircleIcon,
  StarIcon,
  UserIcon,
  WalletIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import BronzeStatus from 'styles/img/statuses/bronze_status.svg'
import GoldStatus from 'styles/img/statuses/gold_status.svg'
import RoyalStatus from 'styles/img/statuses/royal_status.svg'
import SilverStatus from 'styles/img/statuses/silver_status.svg'

import {
  arrowDioganalCircle,
  arrowDownCircle,
  arrowUpCircle,
  blockedIcon,
  clockFill,
  compare,
  diamond_outline,
  spinnerWhite,
} from './images'
import { MainLogo } from './images/MainLogo'

export type IconSizes = 'small' | 'medium' | 'large' | 'base' | 'custom'

export type Icons =
  | 'arrowPath'
  | 'arrowRight'
  | 'documentDuplicate'
  | 'check'
  | 'clock'
  | 'clockFill'
  | 'chevronDown'
  | 'questionMarkCircle'
  | 'chevronUp'
  | 'arrowDownOnSquare'
  | 'arrowTrendingUp'
  | 'pencil'
  | 'envelope'
  | 'eye'
  | 'cloudArrowUp'
  | 'home'
  | 'arrowsRightLeft'
  | 'arrowLeft'
  | 'plus'
  | 'exclamationCircle'
  | 'spinnerWhite'
  | 'exclamationTriangle'
  | 'calendar'
  | 'exclamationTriangleSolid'
  | 'pencilSolid'
  | 'xcircleIcon'
  | 'XCircleIconOutline'
  | 'clockSolid'
  | 'exclamationCircleSolind'
  | 'blockedIcon'
  | 'xMarkIcon'
  | 'checkCircle'
  | 'magnifyingGlassIcon'
  | 'arrowsPointingOut'
  | 'wallet'
  | 'checkBadge'
  | 'informationCircleSolid'
  | 'informationCircleOutline'
  | 'bronzeStatus'
  | 'silverStatus'
  | 'goldStatus'
  | 'royalStatus'
  | 'link'
  | 'diamond_outline'
  | 'mainLogo'
  | 'user'
  | 'arrowDownOutline'
  | 'arrowUpOutline'
  | 'star'
  | 'arrowsUpDown'
  | 'arrowDownTray'
  | 'squaresMenu'
  | 'arrowUpCircle'
  | 'arrowDownCircle'
  | 'arrowDioganalCircle'
  | 'arrowLeftOnRectangle'
  | 'playCircle'
  | 'arrowTopRightOnSquare'
  | 'arrowRightCircle'
  | 'arrowUpOnSquare'
  | 'compare'
  | 'funnel'
  | 'arrowLongUpIcon'
  | 'adjustmentsHorizontal'

type IconsProps = {
  name: Icons
  size: IconSizes
}

const getIcon = ({ name, size }: IconsProps) => {
  const icons = {
    arrowPath: <ArrowPathIcon />,
    documentDuplicate: <DocumentDuplicateIcon />,
    check: <CheckIcon />,
    clock: <ClockIcon />,
    clockFill: <CustomIcon src={clockFill} data-size={size} />,
    chevronDown: <ChevronDownIcon />,
    chevronUp: <ChevronUpIcon />,
    questionMarkCircle: <QuestionMarkCircleIcon />,
    arrowDownOnSquare: <ArrowDownOnSquareIcon />,
    arrowTrendingUp: <ArrowTrendingUpIcon />,
    pencil: <PencilIcon />,
    pencilSolid: <PencilSolid />,
    envelope: <EnvelopeIcon />,
    eye: <EyeIcon />,
    cloudArrowUp: <CloudArrowUpIcon />,
    home: <HomeIcon />,
    arrowsRightLeft: <ArrowsRightLeftIcon />,
    plus: <PlusIcon />,
    exclamationCircle: <ExclamationCircleIcon />,
    spinnerWhite: <CustomIcon src={spinnerWhite} data-size={size} />,
    exclamationTriangle: <ExclamationTriangleIcon />,
    calendar: <CalendarDaysIcon />,
    exclamationTriangleSolid: <ExclamationTriangleSolid />,
    xcircleIcon: <XCircleIcon />,
    XCircleIconOutline: <XCircleIconOutline />,
    clockSolid: <ClockIconSolid />,
    exclamationCircleSolind: <ExclamationCircleSolind />,
    blockedIcon: <CustomIcon src={blockedIcon} data-size={size} />,
    xMarkIcon: <XMarkIcon />,
    checkCircle: <CheckCircleIcon />,
    magnifyingGlassIcon: <MagnifyingGlassIcon />,
    arrowLeft: <ArrowLeftIcon />,
    wallet: <WalletIcon />,
    checkBadge: <CheckBadgeIcon />,
    informationCircleSolid: <InformationCircleSolid />,
    informationCircleOutline: <InformationCircleOutline />,
    bronzeStatus: <CustomIcon src={BronzeStatus} data-size={size} />,
    silverStatus: <CustomIcon src={SilverStatus} data-size={size} />,
    goldStatus: <CustomIcon src={GoldStatus} data-size={size} />,
    royalStatus: <CustomIcon src={RoyalStatus} data-size={size} />,
    link: <LinkIcon />,
    diamond_outline: <CustomIcon src={diamond_outline} data-size={size} />,
    // mainLogo: <CustomIcon src={mainLogo} data-size={size} />,
    mainLogo: <MainLogo data-size={size} />,
    user: <UserIcon />,
    arrowDownOutline: <ArrowDownIcon />,
    arrowUpOutline: <ArrowUpIcon />,
    star: <StarIcon />,
    arrowsUpDown: <ArrowsUpDownIcon />,
    arrowDownTray: <ArrowDownTrayIcon />,
    arrowRight: <ArrowRightIcon />,
    squaresMenu: <Squares2X2Icon />,
    arrowUpCircle: <CustomIcon src={arrowUpCircle} data-size={size} />,
    arrowDownCircle: <CustomIcon src={arrowDownCircle} data-size={size} />,
    arrowDioganalCircle: (
      <CustomIcon src={arrowDioganalCircle} data-size={size} />
    ),
    arrowLeftOnRectangle: <ArrowLeftOnRectangleIcon />,
    playCircle: <PlayCircleIcon />,
    arrowTopRightOnSquare: <ArrowTopRightOnSquareIcon />,
    arrowRightCircle: <ArrowRightCircleIcon />,
    arrowUpOnSquare: <ArrowUpOnSquareIcon />,
    compare: <CustomIcon src={compare} data-size={size} />,
    arrowsPointingOut: <ArrowsPointingOutIcon />,
    funnel: <FunnelIcon />,
    arrowLongUpIcon: <ArrowLongUpIcon />,
    adjustmentsHorizontal: <AdjustmentsHorizontalIcon />
  }

  return icons[name]
}

type IconProps = {
  className?: string
  size?: IconSizes
  name: Icons
  onClick?: React.MouseEventHandler<HTMLDivElement>
  disabled?: boolean
  visible?: boolean
  iconRef?: any
}

export const Icon2: React.FC<IconProps> = ({
  size = 'medium',
  className,
  onClick,
  name,
  disabled,
  iconRef,
  visible = true,
}) => {
  const id = `${name}-icon${Boolean(onClick) && '-clickable'}`

  return (
    <Container
      className={className}
      id={id}
      ref={iconRef}
      name={name}
      onClick={onClick}
      data-size={size}
      data-disabled={disabled}
      data-visible={visible}
    >
      {getIcon({
        name,
        size,
      })}
    </Container>
  )
}

const Container = styled.div<IconProps>`
  &[data-size='small'] {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }

  &[data-size='medium'] {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &[data-size='base'] {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &[data-size='large'] {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  &[data-disabled='true'] {
    opacity: 0.25;
  }

  &[data-visible='false'] {
    display: none;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 3;
  }

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }

      &:hover {
        opacity: 0.8;
        transition: var(--transition);
      }
    `}
`

const CustomIcon = styled.div<{ src: string }>`
  background-image: url('${(p) => p.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &[data-size='small'] {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }

  &[data-size='base'] {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &[data-size='medium'] {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &[data-size='large'] {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  &[data-size='custom'] {
    width: 100%;
    height: 100%;
  }

  &[data-disabled='true'] {
    opacity: 0.25;
  }
`
