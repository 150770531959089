import styled from 'styled-components'
import { Title as UiTitle } from 'ui/atoms'

export const Container = styled.div`
  margin-bottom: 56px;
  padding: 48px 32px;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: var(--color-gray-300);
  border-radius: 8px;

  &:not(:first-child) {
    margin-top: -8px;
    padding-top: 56px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @media (max-width: 599px) {
    padding: 32px 24px;
    margin-bottom: 40px;

    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`

export const Title = styled(UiTitle)<{ background?: string }>`
  padding-top: 136px;
  margin-bottom: 16px;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 120px auto;
`

export const Text = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`
