import styled from 'styled-components'
import { Button, Title as UiTitle } from 'ui/atoms'

export const ChoiceWrapper = styled.div<{
  background?: string
  vsIcon: string
}>`
  margin: 56px 0;
  padding: 48px 40px;
  text-align: center;
  background-color: var(--color-gray-700);
  border-radius: 8px;
  background-image: url(${({ vsIcon }) => vsIcon}),
    url(${({ background }) => background});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 56px auto, cover;

  &:first-child {
    margin-bottom: 0;
    position: relative;
  }

  @media (max-width: 767px) {
    margin: 40px 0;
    padding: 24px 8px;
    background-image: url(${({ background }) => background});
    background-size: cover;

    &:first-child {
      margin-bottom: 0;
    }
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`

export const TeamsWrapper = styled.div`
  margin: 0 auto;
  max-width: 676px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 136px;

  @media (max-width: 767px) {
    gap: 16px;
  }
`

export const TeamImage = styled.div`
  position: relative;
  padding-bottom: 111%;

  @media (max-width: 767px) {
    padding-bottom: 147%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

export const TeamData = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: var(--color-gray-100);

  @media (max-width: 599px) {
    left: 8px;
    bottom: 8px;
  }
`

export const ChoiceButton = styled(Button)`
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;

  @media (max-width: 767px) {
    margin-top: 8px;
    width: 100%;
    min-width: auto;
  }
`
