import { useStore } from 'effector-react'
import { $isUserEmailConfirmed, $userRole } from 'features/user'
import { path } from 'libs/path'
import {
  AccountDetails,
  AccountsCreate,
  AccountsCreateChoosePlatform,
  AccountsCreateChooseTariff,
  AccountsCreateSettings,
  AccountsPage,
  ChangePasswordPage,
  ContestDetails,
  ContestsListPageLazy,
  Copytrade,
  Dashboard,
  DepositCustomPage,
  Error404,
  ExchangeTransfer,
  ExchangerArea,
  GiftDetails,
  Giftshop,
  IBArea,
  LoginHistoryPage,
  MoneyPage,
  ProfileContainer,
} from 'pages'
import {
  LoginEntry,
  OathRecordsCheck,
  PasswordRecoveryChange,
  PasswordRecoveryConfirm,
  PasswordRecoveryEntry,
  PasswordRecoverySuccess,
  RegisterConfirmSuccess,
  RegisterEmail,
  RegisterEmailConfirm,
  RegisterEmailConfirmRepeat,
  RegisterEntry,
  RegisterPassword,
} from 'pages/auth'
import {
  InProgressPage,
  RequestPage,
  UpdateDocs,
  VerificationLayout,
  VerifiedPage,
} from 'pages/dashboard/verification'
import {
  ResolveWithdrawalPages,
  ResultDepositPage,
} from 'pages/money/resultPages'
import { ResolveWithdrawalPage } from 'pages/money/WithdrawalPage/ResolveWithdrawalPage'
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom'
import { AuthTemplate, GenericTemplate } from 'ui/templates'
import { PublicTemplate } from 'ui/templates/public'

const ProtectedElement = ({ element }: { element: JSX.Element }) => {
  const { pathname, search } = useLocation()

  const userRole = useStore($userRole)
  const isUserEmailConfirmed = useStore($isUserEmailConfirmed)

  let isDev = false
  // isDev = env.isDev

  if (userRole === 'authorized' || isDev) {
    if (isUserEmailConfirmed === false) {
      return <Navigate to={path.register.email.confirm()} />
    }

    return element
  }

  localStorage.setItem('returnUrl', `${pathname}${search}`)

  return <LoginEntry />
}

export const Router = () => {
  const userRole = useStore($userRole)

  if (!userRole) {
    return null
  }

  let isDev = false
  // isDev = env.isDev

  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <>
            <Route
              path={path.root()}
              element={
                <Navigate
                  to={
                    userRole === 'authorized' || isDev
                      ? path.dashboard.entry()
                      : path.login.entry()
                  }
                />
              }
            />
            <Route element={<AuthTemplate />}>
              <Route element={<LoginEntry />}>
                <Route path={path.login.entry()} />
                <Route path={path.login.email()} />
              </Route>

              <Route
                path={path.register.oOath.entry()}
                element={<OathRecordsCheck />}
              />

              <Route
                path={path.register.password.recovery.entry()}
                element={<PasswordRecoveryEntry />}
              />
              <Route
                path={path.register.password.recovery.confirm()}
                element={<PasswordRecoveryConfirm />}
              />
              <Route
                path={path.register.password.recovery.change()}
                element={<PasswordRecoveryChange />}
              />
              <Route
                path={path.register.password.recovery.success()}
                element={<PasswordRecoverySuccess />}
              />

              <Route path={path.register.entry()} element={<RegisterEntry />} />
              <Route
                path={path.register.email.registration()}
                element={<RegisterEmail />}
              />
              <Route
                path={path.register.email.password()}
                element={<RegisterPassword />}
              />
              <Route
                path={path.register.email.confirm()}
                element={<RegisterEmailConfirm />}
              />
              <Route
                path={path.register.email.confirmRepeat()}
                element={<RegisterEmailConfirmRepeat />}
              />
              <Route
                path={path.register.email.confirmSuccess()}
                element={<RegisterConfirmSuccess />}
              />
            </Route>

            <Route
              element={
                userRole === 'authorized' || isDev ? (
                  <GenericTemplate withActivities />
                ) : (
                  <AuthTemplate />
                )
              }
            >
              <Route
                path={path.dashboard.entry()}
                element={<ProtectedElement element={<Dashboard />} />}
                handle={{ name: 'Dashboard' }}
              />
            </Route>

            <Route
              element={
                userRole === 'authorized' || isDev ? (
                  <GenericTemplate withCrumbs />
                ) : (
                  <AuthTemplate />
                )
              }
            >
              <Route
                path={path.dashboard.profile.entry()}
                element={<ProtectedElement element={<ProfileContainer />} />}
                handle={{ name: 'Profile' }}
              />

              <Route handle={{ name: 'Exchanger area' }}>
                <Route
                  path={path.exchanger.entry()}
                  element={<ProtectedElement element={<ExchangerArea />} />}
                />
                <Route
                  path={path.exchanger.transfer()}
                  element={<ProtectedElement element={<ExchangeTransfer />} />}
                  handle={{ name: 'Transfer to client' }}
                />
              </Route>

              <Route handle={{ name: 'Accounts' }}>
                <Route
                  element={<ProtectedElement element={<AccountsPage />} />}
                >
                  <Route path={path.accounts.entry()} />
                  <Route path={path.accounts.moneyHistoryAccounts()} />
                </Route>

                <Route
                  path={path.accounts.details(':accountID')}
                  element={<ProtectedElement element={<AccountDetails />} />}
                  handle={{ name: 'Account Details' }}
                />

                <Route
                  element={<ProtectedElement element={<AccountsCreate />} />}
                  handle={{ name: 'Create Account' }}
                >
                  <Route
                    path={path.accounts.create.tariff(':accountType')}
                    element={<AccountsCreateChooseTariff />}
                    handle={{ name: 'Account type' }}
                  />
                  <Route
                    path={path.accounts.create.platform(':accountType')}
                    element={<AccountsCreateChoosePlatform />}
                    handle={{ name: 'Platform' }}
                  />
                  <Route
                    path={path.accounts.create.settings(':accountType')}
                    element={<AccountsCreateSettings />}
                    handle={{ name: 'Settings' }}
                  />
                </Route>
              </Route>

              <Route
                element={<ProtectedElement element={<VerificationLayout />} />}
                handle={{ name: 'Verification' }}
              >
                <Route
                  path={path.dashboard.verification.entry()}
                  element={<RequestPage />}
                />
                <Route
                  path={path.dashboard.verification.progress()}
                  element={<InProgressPage />}
                />
                <Route
                  path={path.dashboard.verification.verified()}
                  element={<VerifiedPage />}
                />
                <Route
                  path={path.dashboard.verification.updateDocs()}
                  element={<UpdateDocs />}
                />
              </Route>

              <Route
                element={<ProtectedElement element={<IBArea />} />}
                handle={{ name: 'IB Area' }}
              >
                <Route path={path.ib.entry()} />
                <Route path={path.ib.materials()} />
              </Route>

              <Route 
               element={<ProtectedElement element={<ContestsListPageLazy/>} />}
               handle={{ name: 'Contests' }}
               path={path.contests.entry()}
              />

              <Route handle={{ name: 'Money' }}>
                <Route element={<ProtectedElement element={<MoneyPage />} />}>
                  <Route path={path.money.entry()} />
                  <Route path={path.money.depositForm(':gatewayId')} />
                  <Route path={path.money.withdrawalTab()} />
                  <Route path={path.money.cardsTab()} />
                  <Route path={path.money.internalTransfer()} />
                  <Route path={path.money.moneyHistory()} />
                </Route>
                <Route
                  path={path.money.customWithdrawal(':gatewayId')}
                  element={
                    <ProtectedElement element={<ResolveWithdrawalPage />} />
                  }
                />
                <Route
                  element={<ProtectedElement element={<ResultDepositPage />} />}
                >
                  <Route path={path.money.resultDeposit()} />
                </Route>

                <Route
                  path={path.money.resultWithdrawal()}
                  element={
                    <ProtectedElement element={<ResolveWithdrawalPages />} />
                  }
                />

                <Route
                  path={path.money.h2pCustom()}
                  element={<ProtectedElement element={<DepositCustomPage />} />}
                />
              </Route>

              <Route handle={{ name: 'Giftshop' }}>
                <Route element={<ProtectedElement element={<Giftshop />} />}>
                  <Route path={path.giftshop.entry()} />
                  <Route path={path.giftshop.history()} />
                </Route>
                <Route
                  path={path.giftshop.details(':giftId')}
                  element={<ProtectedElement element={<GiftDetails />} />}
                />
              </Route>

              <Route
                element={<ProtectedElement element={<Copytrade />} />}
                handle={{ name: 'Copytrade' }}
              >
                <Route path={path.copytrade.createStrategy()} />
                <Route path={path.copytrade.compareStrategies()} />
                <Route path={path.copytrade.startCopying.selectAccount()} />
                <Route path={path.copytrade.startCopying.accountSettings()} />
                <Route path={path.copytrade.startCopying.replenishAccount()} />
                <Route path={path.copytrade.editStrategy(':strategyID')} />
                <Route path={path.copytrade.strategyForTrader(':strategyID')} />
              </Route>

              <Route handle={{ name: 'Contests' }}>
                <Route
                  path={path.contests.details(':contestName')}
                  element={<ProtectedElement element={<ContestDetails />} />}
                />
              </Route>
            </Route>

            <Route
              element={
                userRole === 'authorized' ? (
                  <GenericTemplate />
                ) : (
                  <PublicTemplate />
                )
              }
              handle={{ name: 'Copytrade' }}
            >
              <Route element={<Copytrade />}>
                <Route path={path.copytrade.traderEntry()} />
                <Route path={path.copytrade.investorEntry()} />
                <Route
                  path={path.copytrade.strategyForInvestor(':strategyID')}
                />
              </Route>
            </Route>

            <Route
              element={
                userRole === 'authorized' || isDev ? (
                  <GenericTemplate />
                ) : (
                  <AuthTemplate />
                )
              }
            >
              {/* custom breadcrumbs */}
              <Route
                path={path.dashboard.profile.loginHistory()}
                element={<ProtectedElement element={<LoginHistoryPage />} />}
                handle={{ name: 'Login history' }}
              />
              <Route
                path={path.dashboard.profile.changePassword()}
                element={<ProtectedElement element={<ChangePasswordPage />} />}
                handle={{ name: 'Change password' }}
              />

              {/* 404 */}
              <Route
                path="*"
                element={
                  <Error404 isInner={userRole === 'authorized' || isDev} />
                }
              />
            </Route>
          </>,
        ),
        { basename: '/user' },
      )}
    />
  )
}
