import styled, { css, keyframes } from 'styled-components/macro'
import { Button as UiButton, Title as UiTitle } from 'ui/atoms'

import success from './images/success.png'

const iconsMap: Record<string, string> = {
  success,
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const scaleIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const scaleOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
`

export const Content = styled.div<{ width: number }>`
  position: relative;
  padding: 28px;
  padding-bottom: 20px;
  width: ${({ width }) => `${width}px`};
  animation: ${scaleOut} 0.25s forwards;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-shadow: 0px 10px 10px -4px #2621190f, 0px 20px 24px -4px #2621191a;

  @media (max-width: 599px) {
    padding: 20px;
    padding-top: 24px;
  }
`

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeOut} 0.25s forwards;
  pointer-events: none;
  z-index: 1000000; // todo: это просто чтобы перекрыть zendesk 😭 потом обязательно убрать!!
  background-color: #26211933;
  backdrop-filter: blur(1px);

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      animation: ${fadeIn} var(--transition) forwards;

      ${Content} {
        animation: ${scaleIn} 0.25s forwards;
      }
    `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  transition: opacity var(--transition);

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.3;
  }

  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      right: auto;
      left: 8px;
    `}
`

export const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
`

export const Button = styled(UiButton)`
  width: 100%;
`

export const Title = styled(UiTitle)<{ icon?: string }>`
  margin-bottom: 20px;

  @media (max-width: 599px) {
    margin-bottom: 16px;
  }

  ${({ icon }) =>
    !!icon &&
    css`
      padding-top: 144px;
      text-align: center;
      background-image: url(${iconsMap[icon]});
      background-repeat: no-repeat;
      background-size: 128px auto;
      background-position: top center;
    `}
`
