import { useWindowSize } from '@uidotdev/usehooks'
import { CountryName } from 'pages/contest/molecules/countryName'
import { useTranslation } from 'react-i18next'
import { Contest, LeaderboardRow } from 'services/contests/types'
import { Icon2, Money } from 'ui/atoms'

import { icons } from '../../images/icons'
import * as Styled from './styled'

const TableRow = (props: LeaderboardRow) => {
  const [t] = useTranslation()

  return (
    <Styled.TableRow selected={props.isYou}>
      <Styled.PlaceWrapper>
        {props.place > 10 ? (
          props.place
        ) : (
          <img
            src={icons[`smallMedal_${props.place}`]}
            alt={`${props.place}`}
          />
        )}
      </Styled.PlaceWrapper>
      <Styled.NameWrapper>
        {props.isYou ? t('You') : props.name}
        <CountryName code={props.country} />
      </Styled.NameWrapper>
      <Styled.TeamWrapper>
        <div className="flex items-center gap-[8px]">
          <img src={props.team.icon.web} alt={props.team.name} />
          {props.team.name}
        </div>
      </Styled.TeamWrapper>
      <Styled.RegularCell>
        <Money value={+props.equity} />
      </Styled.RegularCell>
      <Styled.RegularCell>
        {+props.prize ? (
          <Money value={+props.prize} minimumFractionDigits={0} />
        ) : null}
      </Styled.RegularCell>
    </Styled.TableRow>
  )
}

export const RatingTable = (
  props: Pick<Contest, 'leaderboard'> & { isFull: boolean },
) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width && width < 800

  if (!props.leaderboard) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.Title level={2}>Leaderboard</Styled.Title>
      <div>
        {!isMobileMode && (
          <Styled.TableHead>
            <div>{t('Place')}</div>
            <div className="ps-[16px] text-left">{t('Participant')}</div>
            <div>{t('Team')}</div>
            <div>{t('Equity')}</div>
            <div>{t('Prize')}</div>
          </Styled.TableHead>
        )}
        {props.leaderboard?.items.map((leader) => (
          <TableRow {...leader} key={leader.place} />
        ))}
        {props.isFull && props.leaderboard.current?.place > 10 && (
          <>
            <Styled.TopTraders>
              <Icon2 name="arrowLongUpIcon" size="small" />
              {t('Top traders')}
              <Icon2 name="arrowLongUpIcon" size="small" />
            </Styled.TopTraders>
            <div>
              <TableRow {...props.leaderboard.current} />
            </div>
          </>
        )}
      </div>
    </Styled.Container>
  )
}
