import { useWindowSize } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'
import { Icon2 } from 'ui/atoms'

import * as Styled from './styled'
import { ModalProps } from './types'

export const Modal = ({
  open,
  closable = true,
  children,
  onClose,
  onOk,
  width = 360,
  footer = true,
  title,
  okLabel = 'OK',
  icon,
}: ModalProps) => {
  const { width: screenWidth } = useWindowSize()
  const isMobileMode = screenWidth && screenWidth < 600

  // для красивой анимации
  const [shouldRender, setShouldRender] = useState(open)

  // для закрытия по нажатию на esc
  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }

    onClose()
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'

      setShouldRender(true)

      window.addEventListener('keydown', handleEsc)

      return () => {
        window.removeEventListener('keydown', handleEsc)
      }
    } else {
      document.body.style.overflow = 'visible'

      // оставляем модальное окно в dom до завершения анимации
      const timer = setTimeout(() => setShouldRender(false), 250)
      return () => clearTimeout(timer)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  return (
    <Styled.Overlay isOpen={open} onClick={onClose}>
      <Styled.Content
        onClick={(event) => event.stopPropagation()}
        width={width}
      >
        <Styled.CloseButton onClick={onClose} type="button">
          <Icon2 name="xMarkIcon" />
        </Styled.CloseButton>
        {!!title && (
          <Styled.Title
            level={isMobileMode ? 3 : 2}
            withTranslate={false}
            icon={icon}
          >
            {title}
          </Styled.Title>
        )}
        {children}
        {footer ? (
          <Styled.Footer>
            {/* если логика ok и cancel отличается, то показываем кнопку cancel */}
            {!!onOk ? (
              <Styled.Button
                size={isMobileMode ? 'small' : 'medium'}
                design="secondary"
                onClick={onClose}
              >
                Cancel
              </Styled.Button>
            ) : null}
            <Styled.Button
              size={isMobileMode ? 'small' : 'medium'}
              onClick={handleOk}
            >
              {okLabel}
            </Styled.Button>
          </Styled.Footer>
        ) : null}
      </Styled.Content>
    </Styled.Overlay>
  )
}
